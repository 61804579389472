#footer {
  margin-top: 10rem;
}

#content {
  color: var(--white);
  text-align: center;
}

#contact {
  font-size: 8rem;
  margin-left: 8vh;
  margin-bottom: 0;
  text-transform: uppercase;
}

#walkietalkie {
  position: absolute;
  left: 0%;
  height: 55vw;
  margin-top: -5vw;
}

#text {
  display: flex;
  flex-direction: column;
  padding: 5vh 8vh 5vh;
  justify-content: center;
  align-items: center;
  width: 60vw;
  padding-left: 35vw;
}

.sec {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  align-self: center;
}

.sec p,
.sec a {
  font-family: 'Abel', sans-serif;
  font-size: 1.5rem;
  text-align: center;
  margin: .5rem 0 0 0;
  overflow-wrap: normal;
}

.sec a {
  margin: 0 1rem;
  text-decoration: underline;
}

#mail {
  display: flex;
  justify-content: center;
  align-items: center;
}

#icon-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#mail,
#icon-box {
  margin-top: 1.25rem;
}

.icon {
  display: inline-block;
  margin: 2.5rem;
}

.footer-icon {
  width: 3rem;
  height: 3rem;
}

.footer-links {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

a {
  color: var(--black);
}


@media (max-width: 1250px) {
  #contact {
    font-size: 7rem;
    margin-top: 0;
  }

}

@media (max-width: 1050px) {
  #contact {
    font-size: 6rem;
    padding-top: 2vh;
  }
}

@media (max-width: 820px) {
  #contact {
    margin-left: 0;
    margin-top: -20vw;
  }
}

@media (max-width: 480px) {
  #contact {
    font-size: 2.5rem;
  }

  .sec p,
  .sec a {
    line-height: 1rem;
    font-size: 16px;
    margin: .5rem 0;
  }

  .sec {
    margin: 1rem 0;
  }

  #mail,
  #icon-box {
    margin-top: .25rem;
  }

  #mail {
    margin-bottom: 1rem;
  }

  .footer-icon {
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 390px) {
  #footer {
    margin-top: 20vw;
  }

  .sec p,
  .sec a {
    line-height: .75rem;
    font-size: 12px;
    margin: .25rem 0;
  }

}