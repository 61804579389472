#sponsors-wrapper {
    color: var(--white);
    margin-top: -5px;
    margin-bottom: 120vh;
}

#bottle {
    position: absolute;
    left: 0%;
    width: 35vw;
    z-index: 0;
}

.rightText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    padding-left: 35%;
    margin-top: -50rem;
}

#sponsors {
    font-size: 8rem;
    line-height: 2rem;
    text-transform: uppercase;
    text-align: center;
    margin-left: 0;
    margin-bottom: 5rem;
    padding-top: 2rem;
}

#sponsor-us {
    font-size: 2rem;
    text-align: center;
    margin: 0;
    font-family: 'Abel';
}

#dei {
    font-size: 1.25rem;
    text-align: center;
    margin: 8rem;
    font-family: 'Abel';
    font-style: italic;
}

#email {
    font-size: 2rem;
    font-weight: bold;
    text-decoration: underline;
    color: var(--white);
    font-family: 'Abel';
}

#sponsor-images {
    position: relative;
    width: 20vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
    margin: 0 8rem;
    padding: 18rem 0 0 0;
}

#sponsor-images img {
    height: auto;
    max-width: 6rem;
    margin: .5rem;
    cursor: pointer;
    z-index: 1;
}

#sponsor-images img:hover {
    transform: scale(1.1);
}

.white-logo {
    filter: invert(1);
}

@media (max-width: 1180px) {
    #sponsors-wrapper {
        margin-bottom: 60vw;
    }

    #sponsor-images img {
        max-width: 4.5rem;
    }

    #sponsor-images {
        margin: 0 7rem;
        padding: 18rem 0 0 0;
    }
}

@media (max-width: 1024px) {
    #sponsor-images img {
        max-width: 4rem;
    }

    #sponsor-images {
        margin: 0 6rem;
        padding: 15rem 0 0 0;
    }

    #sponsors {
        padding-top: 20vw;
    }
}

@media (max-width: 820px) {
    #sponsor-images img {
        max-width: 3rem;
    }

    #sponsor-images {
        margin: 0 5rem;
        padding: 10rem 0 0 0;
    }

    #sponsors-wrapper {
        margin-top: 70vw;
        margin-bottom: 30vh;
    }

    #sponsors {
        font-size: 6rem;
        padding-top: 40vw;
    }

    #sponsor-us,
    #email {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    #sponsor-images img {
        max-width: 1rem;
        margin: .25rem;
    }

    #sponsor-images {
        margin: 0 2.5rem;
        padding: 5rem 0 0 0;
    }

    #sponsors-wrapper {
        margin-top: 60vw;
        margin-bottom: 30vh;
    }

    #sponsors {
        font-size: 2.5rem;
        padding-top: 130vw;
        margin-bottom: 2rem;
    }

    #sponsor-us,
    #email {
        font-size: 1.25rem;
    }
}

@media (max-width: 390px) {
    #sponsors-wrapper {
        margin-top: 70vw;
    }

    #sponsor-us,
    #email {
        font-size: 1rem;
    }
}