.navbar-container {
    align-items: center;
    display: flex;
    width: 100vw;
    min-height: 15%;
    position: fixed;
    top: 0;
    z-index: 20;
    transition: all .2s ease-in;
    font-size: 2rem;
      background: linear-gradient(348deg, #cfba8b 55.95%, rgba(16, 113, 81, 0.01) 133.72%);

}

.scrolled {
    background-color: var(--dark-green);
}

.navbar-links {
    display: flex;
    margin-top: 1rem;
}

.nav-menu {
    display: flex;
    justify-content: center;
    justify-self: center;
}

.hamburger {
    border: 0;
    height: 4rem;
    width: 4rem;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: var(--space-purple);
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    right: 25px;
    transform: translateY(-50%);
    display: none;
}

.hamburger:hover {
    background-color: var(--purple);
}

.navbar-title,
.navbar-links a {
    margin: 0 2rem;
    color: var(--white);
}

.navbar-title {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 2rem;
}

.navbar-title img {
    height: 4.5rem;
    width: 4.5rem;
    margin: 1rem 0;
}

.underline {
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
}

.underline:hover {
    color: white;
}


@media (max-width: 1024px) {
    .navbar-title img {
        display: none;
    }

    .navbar-container {
        padding: 0;
        flex-flow: column nowrap;
        justify-content: center;
    }

    .underline {
        font-size: 1.5rem;
        margin-top: 1rem;
    }

}

@media (max-width: 820px) {
    .navbar-container {
        margin-bottom: -1rem;
    }
}

@media (max-width: 480px) {
    .navbar-container {
        padding: 2rem 0;
        min-height: 3vh;
    }

    .underline {
        font-size: 15px;
    }

    .navbar-title,
    .navbar-links a {
        margin: 0 .75rem;
    }
}

@media (max-width: 375px) {
    .navbar-container {
        min-height: 13vh;
        padding: 1rem;
        padding-top: 1.5rem;
    }
}