/* import fonts */
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Dokdo&display=swap');

/* colors */
:root {
  --forest-green: #ABC0B9;
  --dark-green: #2D5855;
  --white: #FFFFFF;
  --gray: #020202;
  --black: #0F0237;
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-padding-top: 18vh;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background: var(--forest-green);
  width: 100vw;
}

* {
  font-family: Arial, sans-serif;
  font-family: "Dokdo";
}

a {
  text-decoration: none;
  color: white;
}

.app-container {
  background-color: var(--forest-green);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.section2 {
  margin-top: -50px;
}