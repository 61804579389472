#faq {
    margin: 10vw 0;
}

#faq-title {
    color: var(--white);
    font-size: 8rem;
    margin: 0 50vw 0 10vw;
    text-align: center;
    line-height: 8rem;
    text-transform: uppercase;
}

#compass {
    position: absolute;
    right: 0%;
    margin-top: -30vw;
    width: 35vw;
}

#questions {
    margin: 5rem 6rem;
}

.question {
    display: flex;
    color: var(--white);
    align-items: center;
    font-size: 5rem;
}

.question p {
    margin: 1rem 2rem;
}

.answer,
.answer b {
    color: var(--white);
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-family: 'Nanum Gothic', sans-serif;
}

.answer {
    margin: 1rem 2rem;
}

#hackAnswer {
    margin-right: 30rem;
}

@media (max-width: 1180px) {
    #faq-title {
        font-size: 7rem;
    }

    #compass {
        margin-top: -50vw;
    }

    #hackAnswer {
        margin-right: 0;
    }
}

@media (max-width: 820px) {
    #faq-title {
        font-size: 6rem;
        padding-left: 5vw;
        margin: 0 60vw 10vw 0;
    }

    #compass {
        margin-top: -60vw;
    }

    #questions {
        margin: 0 3rem;
    }

    .question {
        font-size: 4rem;
        line-height: 3rem;
    }

    .answer,
    .answer b {
        font-size: 1.5rem;
        padding-bottom: 5vw;
    }

}

@media (max-width: 480px) {
    #faq-title {
        font-size: 5rem;
        line-height: 4rem;
    }

    #compass {
        margin-top: -70vw;
    }

    #questions {
        margin: 0 1rem;
    }

    .question {
        font-size: 3rem;
    }

    .answer {
        font-size: 16px;
        margin: 0 2rem;
    }

    .answer b {
        font-size: 16px;
    }

}

@media (max-width: 480px) {
    #faq {
        margin-bottom: 5vw;
        font-size: 3.5rem;
    }

    #faq-title {
        font-size: 2.5rem;
        line-height: 4rem;
    }

    .answer {
        margin: 0 2rem;
    }

    .answer,
    .answer b {
        font-size: 16px;
        line-height: 1.5rem;
    }
}


@media (max-width: 390px) {
    .question {
        font-size: 2rem;
        line-height: 1.75rem;

    }

}