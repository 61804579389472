#landing-wrapper {
  padding-top: 10vh;
  margin-bottom: 20vw;
  min-height: max(700px, 100vh);
  background: linear-gradient(348deg, #FFEABB 55.95%, rgba(16, 113, 81, 0.01) 133.72%);
}

/* Foreground = text, buttons, non-animated elements */
#landing-fg {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15rem 0;
}

#landing-title {
  margin: 0;
  color: var(--white);
  font-size: 10rem;
  line-height: 2rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  position: fixed;
}

#landing-date {
  margin-top: 18rem;
  color: var(--dark-green);
  font-size: 3.5rem;
  line-height: 2rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  position: fixed;
}

#landing-bg {
  position: relative;
}

#landing-text {
  margin-bottom: -25vw;
}

#landing-btn-wrapper {
  position: relative;
  top: -13vw;
  left: 61vw;
}

.landing-btn {
  padding: 2.5rem 7rem;
  margin: 1.5rem;
  font-size: 5rem;
  line-height: 2rem;
  color: var(--white);
  border-radius: 3rem;
  cursor: pointer;
  text-transform: uppercase;
}

.landing-btn:hover {
  font-size: 5.2rem;
}

#countdown-text {
  position: relative;
  top: -95.5vw;
  left: 8.5vw;
  font-size: 1.5rem;
  text-transform: uppercase;
}

#timer {
  display: grid;
  align-items: center;
  grid-template-rows: 4rem 4rem 4rem 4rem;
  width: 15vw;
  color: var(--white);
}

#timer p {
  margin: 0;
}

#days-num {
  grid-area: 1 / 1;
}

#days-text {
  grid-area: 1 / 2;
}

#hours-num {
  grid-area: 2 / 1;
}

#hours-text {
  grid-area: 2 / 2;
}

#minutes-num {
  grid-area: 3 / 1;
}

#minutes-text {
  grid-area: 3 / 2;
}

#seconds-num {
  grid-area: 4 / 1;
}

#seconds-text {
  grid-area: 4 / 2;
}

#mountains {
  margin-top: -5rem;
  width: 100vw;
}

#river {
  margin-top: -80rem;
  margin-bottom: -10px;
  width: 100vw;
}

@media (min-width: 1800px) {
  #landing-wrapper {
    margin-top: 5vw;
  }

  #landing-bg {
    position: relative;
    top: -30vh;
  }

  #landing-text {
    margin-bottom: -35vw;
  }

  .timer-element p {
    margin: 25px;
  }

  #countdown-text {
    top: -85vw;
  }

  #landing-btn-wrapper {
    top: -3vw;
    left: 65vw;
  }
}

@media (max-width: 1350px) {
  #countdown-text {
    top: -96vw;
    left: 8vw;
  }

  #timer {
    grid-template-rows: 3.5rem 3.5rem 3.5rem 3.5rem;
  }

  #landing-fg {
    padding: 20vw 0 55vw;
  }

  #landing-title {
    font-size: 8rem;
  }

  #landing-date {
    margin-top: 15rem;
    font-size: 3rem;
  }

  #landing-btn-wrapper {
    left: 58vw;
  }
}

@media (max-width: 1180px) {
  #landing-fg {
    padding: 20vw 0 55vw;
  }

  #countdown-text {
    font-size: 1.25rem;
    left: 8.75vw;
  }

  #landing-title {
    font-size: 8em;
  }

  .landing-btn {
    font-size: 4rem;
  }

  #landing-btn-wrapper {
    left: 59vw;
  }

}

@media (max-width: 1080px) {
  #landing-fg {
    padding: 20vw 0 65vw;
  }

  #countdown-text {
    font-size: 1rem;
    top: -96.5vw;
  }

  #landing-btn-wrapper {
    left: 56vw;
  }

  #timer {
    grid-template-rows: 3rem 3rem 3rem 3rem;
  }
}

@media (max-width: 972px) {
  #landing-fg {
    padding: 20vw 0 80vw;
  }

  #countdown-text {
    top: -97vw;
  }

  .landing-btn {
    font-size: 3rem;
  }

}

@media (max-width: 820px) {
  #landing-wrapper {
    margin-bottom: -50vw;
  }

  #landing-fg {
    padding: 20vw 0 100vw;
  }

  #timer {
    grid-template-rows: 2.5rem 2.5rem 2.5rem 2.5rem;
    font-size: 1rem;
  }

  #landing-title {
    font-size: 5rem;
  }

  #landing-date {
    font-size: 2.5rem;
  }

  .landing-btn {
    font-size: 2.5rem;
  }

  #landing-btn-wrapper {
    left: 54vw;
  }
}

@media (max-width: 768px) {
  #landing-fg {
    padding: 20vw 0 120vw;
  }

  #timer {
    grid-template-rows: 2.25rem 2.25rem 2.25rem 2.25rem;
    font-size: .75rem;
  }

  #landing-title {
    font-size: 5rem;
  }

  #landing-date {
    font-size: 2rem;
  }

  #landing-btn-wrapper {
    left: 53vw;
  }
}

@media (max-width: 480px) {
  #landing-fg {
    padding: 20vw 0 150vw;
  }

  #landing-title {
    font-size: 3rem;
  }

  #landing-date {
    margin-top: 8rem;
    font-size: 1.5rem;
  }

  #timer {
    grid-template-rows: 1.25rem 1.25rem 1.25rem 1.25rem;
    font-size: .5rem;
  }

  #countdown-text {
    top: -100vw;
  }

  .landing-btn {
    font-size: 1.5rem;
  }

  #landing-btn-wrapper {
    left: 39vw;
    top: -15vw;
  }
  
  #river {
    margin-bottom: -5vw;
  }
}

@media (max-width: 390px) {
  #landing-date {
    margin-top: 8rem;
    font-size: 1rem;
  }

  #countdown-text {
    top: -99vw;
  }

  #timer {
    grid-template-rows: 1.25rem 1rem 1.25rem 1.25rem;
    font-size: .4rem;
  }

  #landing-btn-wrapper {
    left: 35vw;
    top: -13vw;
  }
}